export default {
  methods: {
    generateLink (code) {
      let link = location.href.split('/').shift()
      link += '//' + location.host + '/events?code=' + code
      return link
    },
    async copyLink (code) {
      try {
        await navigator.clipboard.writeText(this.generateLink(code))
        this.$displaySnackbar({ message: "Event link successfully copied", success: true })
      } catch (e) {
        this.$displaySnackbar({ message: 'Couldn\'t copy link' })
      }
    }
  }
}
