import { render, staticRenderFns } from "./CampaignDetails.vue?vue&type=template&id=7518a729&scoped=true&"
import script from "./CampaignDetails.vue?vue&type=script&lang=js&"
export * from "./CampaignDetails.vue?vue&type=script&lang=js&"
import style0 from "./CampaignDetails.vue?vue&type=style&index=0&id=7518a729&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7518a729",
  null
  
)

export default component.exports