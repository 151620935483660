<template>
  <div class="campaign-details-wrap">
    <landing-page-header />
    <div class="content-wrapper mx-auto mt-10">
      <v-row>
        <v-col cols="12" md="4">
          <div class="px-4 px-sm-14">
            <v-card width="100%" max-width="400" :to="{name: 'ProfilePage', query: { tab: 'campaigns' }}">
              <v-card-actions>
                <v-icon>mdi-arrow-left</v-icon>
                <small class="ml-1">Back to Campaigns</small>
              </v-card-actions>
            </v-card>
            <v-card width="100%" class="pa-6 mt-5" max-width="400">
              <div v-if="loading" class="d-flex justify-center">
                <v-progress-circular indeterminate color="primary" />
              </div>
              <div v-else>
                <v-card-title class="pa-0">
                  <h4>{{ campaign.name }}</h4>
                </v-card-title>
                <span class="mt-1 attendees">{{ campaign.attendees }} Attendee{{ campaign.attendees > 1 ? 's' : '' }}</span>
                <div class="end-date-info my-7">
                  <span>ENDS ON</span>
                  <div class="end-date">{{ this.$dayjs(campaign.endDate).format('DD MMM, YYYY') }}</div>
                </div>
                <div>
                  <div v-for="(action, i) in actions" :key="i">
                    <v-divider />
                    <div class="d-flex pa-4 tw-cursor-pointer action--item align-center" @click="actionClicked(action.name)">
                      <v-icon class="action--icon" :color="action.color">mdi-{{action.icon}}</v-icon>
                      <span class="icon--underlay" :style="{ backgroundColor: action.color }"></span>
                      <span class="ml-3 font-weight-bold tw-text-sm" :style="{ color: action.color }">{{ action.name }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </v-card>
          </div>
        </v-col>
        <v-col cols="12" md="8">
          <div class="grid-container px-4 px-sm-14">
            <div>
              <v-card class="px-12 py-5" height="210">
                <h5>Amount Raised</h5>
                <div v-if="loading" class="d-flex justify-center">
                  <v-progress-circular indeterminate color="primary" />
                </div>
                <div v-else>
                  <div class="campaign-amount mt-8">
                    &#8358;{{this.$numeral(campaign.amount).format('0,0')}}
                  </div>
                  <div class="mt-4">
                    <v-progress-linear :value="percentage" color="#9AF97C" />
                    <div class="d-flex justify-space-between align-center mt-2 progress">
                      <span>{{ percentage }}%</span>
                      <span>&#8358;{{ this.$numeral(parseInt(campaign.total) - campaign.amount).format('0,0') }} Left</span>
                    </div>
                  </div>
                </div>
              </v-card>
            </div>
            <div>
              <v-card height="210" class="px-9 py-4 overview-wrap">
                <v-card-text class="px-0">
                  <div class="d-flex justify-space-between stats">
                    <span>Stats</span>
                    <small>Total sales {{ sales.length }}</small>
                  </div>
                </v-card-text>
                <div v-if="loading" class="d-flex justify-center">
                  <v-progress-circular indeterminate color="primary" />
                </div>
                <div v-else>
                  <div v-for="(v, k, i) in typesOverview" :key="i" class="overview-grid mb-5">
                    <div class="text-capitalize tw-text-sm">{{ k }}</div>
                    <div class="d-flex">
                      <div class="type-progress d-inline-block">
                        <div class="indicator" :style="{ width: (v / Math.max(...Object.values(typesOverview)) * 100) + '%' }" />
                      </div>
                      <span class="ml-1">{{ v }}</span>
                    </div>
                  </div>
                </div>
              </v-card>
            </div>
            <div class="table-card">
              <v-card min-height="484" class="py-8">
                <div class="d-flex justify-space-between px-6 px-sm-10 flex-wrap">
                  <div class="d-flex flex-wrap">
                    <simple-tabs :tabs="['all', ...Object.keys(typesOverview)]" v-model="typeInView" />
                  </div>
                  <div class="search-field-wrap">
                    <v-text-field v-model="search" class="search-field mb-2" solo hide-details append-icon="mdi-magnify" label="Search Campaigns" />
                  </div>
                </div>
                <v-data-table
                  :headers="headers"
                  :loading="loading"
                  v-if="!$vuetify.breakpoint.xs"
                  :items="tableData"
                  hide-default-footer
                  :mobile-breakpoint="0"
                  :search="search"
                  class="mt-10 px-6 px-sm-10"
                  disable-sort
                >
                  <template #item.date="{ item }">
                    <span>{{ $dayjs(item.date).format('DD MMM, YYYY') }}</span>
                  </template>
                  <template #item.amount="{ item }">
                    <span>{{ $numeral(item.amount).format('0,0') }}</span>
                  </template>
                  <template #item.type="{ item }">
                    <span class="text-capitalize">{{ item.type }}</span>
                  </template>
                </v-data-table>
                <v-data-table v-else class="px-6 mt-10" hide-default-footer hide-default-header :headers="headers" :items="tableData">
                  <template #item="{ item }">
                    <div class="px-2 mb-4 pt-3 pb-4 mobile-table-item">
                      <div class="d-flex justify-space-between align-center">
                        <span class="full--name">{{ item.name }}</span>
                        <span class="date">{{ $dayjs(item.date).format('DD MMM, YYYY') }}</span>
                      </div>
                      <div class="d-flex align-center">
                        <span class="mobile-table-amount">&#8358;{{ $numeral(item.amount).format('0,0') }}</span>
                        <v-icon>mdi-circle-small</v-icon>
                        <span class="text-capitalize mobile-plan-type">{{item.type}}</span>
                      </div>
                    </div>
                  </template>
                </v-data-table>
              </v-card>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-dialog v-model="endCampaignModal" max-width="375">
        <v-card>
          <v-card-title>
            <h4>Ready to end campaign?</h4>
          </v-card-title>
          <v-card-text>
            <p>Ending this campaign means people would not be able to buy merch.</p>
          </v-card-text>
          <v-card-actions class="d-flex flex-column">
            <v-btn color="#00050D" rounded block class="white--text text-uppercase">End Campaign</v-btn>
            <v-btn color="white" depressed @click="endCampaignModal = false" rounded block class="black--text mt-4 text-uppercase">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import BuildHeader from "@/components/build/BuildHeader";
import LandingPageHeader from "@/components/LandingPageHeader";
import CampaignLinkGenerator from "@/mixins/CampaignLinkGenerator";
import SimpleTabs from "@/components/SimpleTabs";
export default {
  name: "CampaignDetails",
  components: { SimpleTabs, LandingPageHeader, BuildHeader },
  mixins: [CampaignLinkGenerator],
  data: () => ({
    campaign: {
      name: 'Rise of the Phoenix - Semicolon C8',
      attendees: 10,
      endDate: new Date(),
      amount: 27400,
      total: 60000,
      eventCode: ''
    },
    endCampaignModal: false,
    search: '',
    typeInView: 'all',
    loading: false,
    actions: [
      {
        name: 'Copy Link',
        color: '#514A41',
        link: '',
        icon: 'link-variant'
      },
      {
        name: 'End Campaign',
        color: '#A52902',
        icon: 'cancel'
      }
    ],
    sales: [
      // {
      //   id: 1,
      //   name: 'Onowomano Iluezi-Ogbaudu',
      //   type: 'basic',
      //   date: new Date('2021-09-21'),
      //   amount: 3000
      // },
      // {
      //   id: 2,
      //   name: 'Moyin Oluwafesobi',
      //   type: 'basic',
      //   date: new Date('2021-09-21'),
      //   amount: 3000
      // },
      // {
      //   id: 3,
      //   name: 'Yinka Peters',
      //   type: 'gold',
      //   date: new Date('2021-08-21'),
      //   amount: 3000
      // },
      // {
      //   id: 4,
      //   name: 'Yinka Peters',
      //   type: 'platinum',
      //   date: new Date('2021-08-21'),
      //   amount: 3000
      // },
      // {
      //   id: 5,
      //   name: 'Yinka Peters',
      //   type: 'medium',
      //   date: new Date('2021-08-21'),
      //   amount: 3000
      // }
    ],
    headers: [
      {
        text: '#',
        value: 'id'
      },
      {
        text: 'Attendees',
        value: 'name'
      },
      {
        text: 'Plan',
        value: 'type'
      },
      {
        text: 'Date',
        value: 'date'
      },
      {
        text: 'Amount (₦)',
        value: 'amount'
      }
    ],
    typesOverview: {}
  }),
  async created () {
    try {
      this.loading = true
      const { data } = await this.$axios(`/events/event/${this.$route.params.id}`)
      const { amount_raised, attendees, event_code, event_date, name, target } = data
      const keys = {
        amount: 'amount_raised',
        name: 'name',
        total: 'target',
        attendees: 'attendee_count',
        endDate: 'event_date',
        eventCode: 'event_code'
      }
      for (const [k, v] of Object.entries(keys)) {
        this.campaign[k] = data[v]
      }
      this.sales = attendees
      this.typesOverview = data.sales_stat
    } catch (e) {
      console.log(e)
    } finally { this.loading = false }
  },
  computed: {
    tableData () {
      if (this.typeInView === 'all') return this.sales
      return this.sales.filter(sale => sale.type === this.typeInView)
    },
    percentage () {
      return Math.round((this.campaign.amount / this.campaign.total) * 100)
    }
  },
  methods: {
    actionClicked (actionName) {
      if (actionName.toLowerCase() === 'end campaign') {
        this.endCampaignModal = true
      }
      if (actionName.toLowerCase() === 'copy link') {
        this.copyLink(this.campaign.eventCode)
      }
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep {

  .content-wrapper {
    max-width: 1171px;
  }

  .v-card {
    background: #FFFFFF;
    border: 1px solid #FAFAFA;
    box-shadow: 0px 12px 24px rgba(173, 173, 173, 0.08), 0px 2px 4px rgba(173, 173, 173, 0.04) !important;
    border-radius: 8px;
  }

  .attendees {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */


    /* Grey/500 */

    color: #7B7777;
  }

  .end-date-info {
    & > span {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      /* identical to box height */

      letter-spacing: 0.1em;
      text-transform: uppercase;

      color: #87827C;
    }

    div {
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      /* identical to box height, or 171% */


      /* Neutral/Black */

      color: #00050D;
    }
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 28px;

    @media (max-width: 767px) {

      & > div {
        grid-column: 1 / span 2;

        &:first-child, &:nth-child(2) {
          .v-card {
            width: 100% !important;
          }
        }
      }
    }

    & > div {
      &:first-child, &:nth-child(2) {
        .v-card {
          width: 400px;
        }
      }
    }

    .table-card {
      grid-column: 1 / span 2;
    }
  }

  .action--item {
    position: relative;

    .icon--underlay {
      position: absolute;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      opacity: 0.1;
      top: 12px;
      left: 12px;
    }
  }

  h5 {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */


    /* Grey/500 */

    color: #7B7777;
  }

  .campaign-amount {
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
    /* identical to box height, or 100% */

    letter-spacing: -0.04em;

    /* Neutral/Black */

    color: #00050D;
  }

  .progress {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    text-align: right;

    /* Grey/400 */

    color: #9A9595;
  }

  .type-progress {
    width: 120px;
    height: 23px;
    position: relative;

    .indicator {
      position: absolute;
      height: 100%;
      background: #4B4DED;
      top: 0;
      left: 0;
    }
  }

  .overview-grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }

  .stats {
    span {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      /* identical to box height, or 133% */


      /* Grey/500 */

      color: #7B7777;
    }

    small {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      /* identical to box height, or 171% */

      text-align: right;

      /* Grey/400 */

      color: #9A9595;
    }
  }

  .search-field {
    .v-input__slot {
      box-shadow: none !important;
      border: 1px solid #EAEAEA !important;
      border-radius: 10px !important;
    }
  }


  .search-field-wrap {
    max-width: 400px;
    width: 100%;

    @media (min-width: 431px) {
      max-width: 230px;
    }
  }

  .v-data-table {
    table {
      thead {
        tr {
          th {
            border-color: #FAFAFA !important;
            padding: 16px !important;
            span {
              font-family: 'DM Sans', sans-serif;
              font-weight: 400;
              font-size: 14px;
              line-height: 24px;
              /* identical to box height, or 171% */


              /* Brown/500 */

              color: #514A41;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            font-style: normal;
            font-weight: 700;
            font-size: 14px !important;
            line-height: 24px !important;
            padding: 24px 16px !important;
            border-color: #FAFAFA !important;
            /* identical to box height, or 171% */


            /* Brown/500 */

            color: #514A41;
            span {

            }
          }
        }
      }
    }
  }

  .mobile-table-item {
    border-bottom: 1px solid #F4F3F3;

    .full--name {
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      /* identical to box height, or 171% */


      /* Brown/500 */

      color: #514A41;
    }

    .date {
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      /* identical to box height, or 200% */

      display: flex;
      align-items: center;
      letter-spacing: 0.01em;

      /* Grey/400 */

      color: #9A9595;
    }

    .mobile-table-amount {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      /* identical to box height, or 171% */


      /* Grey/400 */

      color: #9A9595;
    }

    .mobile-plan-type {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      /* identical to box height, or 171% */


      /* Grey/400 */

      color: #9A9595;
    }
  }

  .overview-wrap {
    overflow-y: auto;
  }
}
</style>
